<template>
  <v-list-item @click="$event => $emit('selectOffer', offer)">
    <v-badge
      :color="badgeColor"
      bottom
      right
      dot
      offset-x="10"
      offset-y="10"
      class="mr-5"
    >
      <v-icon size="30">
        mdi-sale
      </v-icon>
    </v-badge>
    <v-list-item-avatar />
    <v-list-item-content>
      <v-list-item-title>
        {{ offer.title }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ $t('type') }}: {{ offer.type }}
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-content class="text-right">
      <v-list-item-title>
        {{ offer.discountPercent }}%
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>
  
  <script>
  export default {
      name: 'OfferListItem',
      props: {
          offer: {
              required: true,
              type: Object,
              default: () => {}
          }
      },
      computed: {
        badgeColor() {
            return this.offer.active ? 'green' : 'red'
        }
      }
  }
  </script>
  